// 创建企业
// mxy
<template>
  <div class="main">
    <a-spin :spinning="spinning">
      <a-tabs :tabBarStyle="{ textAlign: 'center', borderBottom: 'unset' }">
        <a-tab-pane key="tab1" tab="试用体验版本">
          <div class="box">
            <div @click="demo(item)" v-for="item in demoData" :key="item.id">
              <div :class="selectData.id === item.id ? 'list lists' : 'list '">
                <img class="image" src="@/assets/small.png" alt="" />
                {{ item.industryName }}
              </div>
            </div>
          </div>
          <div>
            <a-button
              type="primary"
              html-type="submit"
              size="large"
              class="login-button"
              @click="createOK"
              :loading="loginBtn"
              :disabled="loginBtn"
            >
              确定
            </a-button>
          </div>
        </a-tab-pane>
      </a-tabs>
    </a-spin>
  </div>
</template>

<script>

import api from '@/api/API'
import { joinDemo, switchTenant, getDemoList, getProfileDetail } from '@/api/IdentityAPI.js'
import { ref } from 'vue'
import axios from 'axios'
export default {
  name: 'account-trial',
  data () {
    return {
      spinning: false,
      demoData: [],
      selectData: '',
      loginBtn: false,
    }
  },
  mounted () {
    this.getJoinDemoTenant()
  },
  methods: {
    createOK () {
      this.$message.loading('正在切换企业')
      joinDemo({ id: this.selectData.trialTemplateId }).then(data => {
        switchTenant(data).then(data => {
          localStorage.setItem('isBan', JSON.stringify(data.isBan))
          this.$store.dispatch('account/setToken', data.rawData)
          this.$store.dispatch('account/setTenant', {
            tenantId: data.tenantId,
          })
          // location.reload()
          var url = window.location.href
          window.location.href = url
          window.location.href = '/WorkManagement/WorkBench'
          getProfileDetail().then(data => {
            if (data.status === 1) {
              this.$message.error('当前账户已被禁用，如有疑问请联系运维人员')
              window.location.href = '/account/login'
              window.localStorage.clear()
            }
          }).catch(err => {
            console.log(err)
          })
        })
          .catch(err => {
            console.log(err)
            this.$message.error('切换失败，请重试')
          },
          )
      })
        .catch(err => {
          console.log(err)
        })
    },
    getJoinDemoTenant () {
      this.spinning = true
      getDemoList().then(data => {
        console.log(data)
        this.spinning = false
        this.demoData = data
      })
    },
    demo (e) {
      this.selectData = e
    },
  },
}
</script>

<style lang="scss" scoped>
@import "@/assets/common.scss";
.main {
  padding: 60px;
  .box {
    overflow-x: hidden;
    overflow-y: auto;
    max-height: 330px;
    padding: 0 20px;
  }
  .list {
    width: 100%;
    padding-left: 5%;
    height: 46px;
    border: 1px solid #dddddd;
    line-height: 46px;
    margin-bottom: 10px;
  }
  .lists {
    border: 1px solid #134ccf;
  }
  .image {
    width: 32px;
    height: 32px;
    margin-right: 5px;
  }

  button.login-button {
    margin-top: 20px;
    padding: 0 15px;
    font-size: 16px;
    height: 40px;
    width: 100%;
    background-color: #134ccf;
    color: #fff;
  }
}
.box::-webkit-scrollbar {
  width: 10px;
  /**/
}
.box::-webkit-scrollbar-track {
  background: rgb(239, 239, 239);
  border-radius: 2px;
}
.box::-webkit-scrollbar-thumb {
  background: #bfbfbf;
  border-radius: 10px;
}
.box::-webkit-scrollbar-thumb:hover {
  background: rgb(197, 197, 197);
}
</style>
