import api from '@/api/API'

export const getProfileDetail = () => api.get('/api/app/app-profile').then(({ data }) => data)

export const getTenantList = () => api.get('/api/app/app-profile/user-tenants-drop-list').then(({ data }) => data)

export const getAllRoles = () => api.get('/api/identity/roles/all').then(({ data }) => data.items)

export const getUserRoles = id =>
  api.get(`/api/identity/users/${id}/roles`).then(({ data }) => data.items)

export const getUsers = (params = { maxResultCount: 10, skipCount: 0 }) =>
  api.get('/api/identity/users', { params }).then(({ data }) => data)

export const getUserById = id => api.get(`/api/identity/users/${id}`).then(({ data }) => data)

export const createUser = body => api.post('/api/identity/users', body).then(({ data }) => data)

export const updateUser = (body, id) =>
  api.put(`/api/identity/users/${id}`, body).then(({ data }) => data)

export const removeUser = id => api.delete(`/api/identity/users/${id}`)

export const updateProfileDetail = body =>
  api.put('/api/identity/my-profile', body).then(({ data }) => data)

export const changePassword = body =>
  api.post('/api/identity/my-profile/change-password', body).then(({ data }) => data)

export const getDemoList = () => api.get('/api/app/sys-industry/industry-demo-tenant').then(({ data }) => data)

export const joinDemo = tenantId => api.post('/api/app/account/join-demo-tenant', tenantId).then(({ data }) => data)

export const switchTenant = id => api.post(`/api/app/account/switch-tenant/${id}`).then(({ data }) => data)
//  {
//   api({
//     method: 'POST',
//     url: `/api/app/account/switch-tenant/${id}`,
//   })
// }
